export default

{
    welcomeScreen: {
        welcome: 'ようこそ、',
        reload: 'リロード',
        clickReload: 'アカウントにゲームを追加したばかりの場合は、[リロード]をクリックしてください。',
        findMore: 'アカウント設定で追跡するゲームをさらに見つける',
        songList: '曲リスト',
        courses: 'コース',
        songs: '曲',
        system: 'システム',
        arcade: 'アーケード',
        singles: 'シングル',
        doubles: 'ダブル',
        singleCourses: 'シングルコース',
        doubleCourses: 'ダブルコース',
        tips: 'ヒントを見る',
        selectGames: 'ゲームを選択',
        highScoreRankings: 'ハイスコアランキング',
        highScore: 'ハイスコア',
        leaderboard: 'リーダーボード',
        seeInfo: '情報を見る',
        hideInfo: '情報を隠す',
    },
    menu: {
        app: 'アプリ',
        overview: '概要',
        settings: '設定',
        logout: 'ログアウト',
        aboutAndInfo: '情報',
        admin: '管理者',
        back: '戻る',
        arcadeRanking: 'アーケードランキング',
        games: 'ゲーム',
        arcadesSelect: 'アーケード',
        login: 'ログイン',
        songLeaderboard: '曲リーダーボード',
        profile: 'プロフィール',
    },
    listScreen: {
        search: '検索',
        filters: 'フィルター',
        filter: 'フィルター',
        target: '目標',
        nameArtist: '曲名 / アーティスト',
        level: 'レベル',
        filterOn: 'オン',
        songName: '曲名',
        favorite: 'お気に入り',
        artistAlias: 'アーティスト / 別名',
        favoriteShort: 'Fav',
        targetShort: '目標',
        searchName: '名前で検索',
        fullComboCap: 'フルコンボ',
        name: '名前',
        artist: 'アーティスト',
        genre: 'ジャンル',
        easy: 'Easy',
        normal: 'Normal',
        hard: 'Hard',
        another: 'Another',
        title: 'タイトル',
        easyLevel: 'Easyレベル',
        normalLevel: 'Normalレベル',
        hardLevel: 'Hardレベル',
        anotherLevel: 'Anotherレベル',
        easyDoubleLevel: 'E Doubleレベル',
        normalDoubleLevel: 'N Doubleレベル',
        hardDoubleLevel: 'H Doubleレベル',
        anotherDoubleLevel: 'A Doubleレベル',
        noSongs: '曲が見つかりません',
    },
    filter: {
        songFilters: '曲フィルター',
        none: 'なし',
        failed: '失敗',
        clear: 'クリア',
        cleared: 'クリア',
        fullCombo: 'フルコンボ',
        single: 'シングル',
        double: 'ダブル',
        singles: 'シングル',
        doubles: 'ダブル',
        favorite: 'お気に入り',
        target: '目標',
        playStyle: 'プレイスタイル',
        otherFilters: 'その他のフィルター',
        applyFilters: 'フィルターを適用',
        filterOnClear: 'クリア時にフィルターを適用',
        level: 'レベル',
        fullComboShort: 'FC',
        setTarget: '目標を設定',
        setFavorite: 'お気に入りを設定',
        save: '保存',
        grade: 'グレード',
        score: 'スコア',
        update: '更新',
        show: '表示',
        filerOn: 'フィルター',
        onlySinglePlayer: 'シングルプレイのみで利用可能',
        onlySinglePlayerInfo: 'マルチプレイでプレイする場合、コースはバトルモードでプレイできません。 タイトルは黄色くなり、コースが選択されるとカップルモードに切り替わります。',
    },
    login: {
        viewProfile: '見る',
        copyCode: 'コードをコピー',
        copied: 'コピーしました',
        languageIs: '日本語',
        english: '英語',
        japanese: '日本語',
        close: '閉じる',
        login: 'ログイン',
        selectLanguage: '言語を選択',
        rankingUrl: 'ランキングURL',
        aboutApp: 'アプリについて',
        register: '登録',
        password: 'パスワード',
        version: 'バージョン',
        email: 'メールアドレス',
        progressTracker: '進捗トラッカー',
        emailIsSave: 'メールアドレスは他の人と共有されません。',
        nameInProfile: 'プロフィールに表示されます。',
        gametagName: 'ゲームタグ / 名前',
        profileUrl: 'プロフィールURL',
        formInvalid: 'フォームが無効です',
        confirmPassword: 'パスワードを認証する',
        goLogin: 'ログインする',
        passwordReset: 'パスワードをリセット',
        emailWillBeSendToReset: 'パスワードをリセットするためのメールが送信されます。',
        sendReset: 'リセットを送信',
    },
    settings: {
        settings: 'アカウント設定',
        singlePLay: 'シングルプレイ',
        doublePlay: 'ダブルプレイ',
        accountDetails: 'アカウント詳細',
        gameSelect: 'ゲームを選択',
        gameSelectInfo: '追跡するゲームを選択します。',
        accountInfo: 'アカウント情報を設定します。',
        arcadeCode: 'アーケードコード',
        info: '情報',
        favoriteGame: 'お気に入りのゲーム',
        selectTheme: 'テーマを選択',
        updated: '更新!',
        codeSlot1: 'スロット1',
        codeSlot2: 'スロット2',
        codeSlot3: 'スロット3',
        seeRanking: 'ランキングを見る',
    }
}

